<template>
  <div class="start_cover" v-if="gameStart == false">
    <button
      type="button"
      class="start_button"
      @click="clickStart"
      :disabled="timeStart == false"
    >
      Start<img class="start_logo" src="../assets/circle-play-regular.svg" />
    </button>
  </div>
  <div class="container">
    <div class="loading-bar">
      <div
        class="percentage"
        :style="{ width: percentage + '%' }"
        id="percentage"
      >
        <div class="percentage_number">
          {{ Math.floor(this.percentage) + " %" }}
        </div>
      </div>
    </div>
  </div>
  <div
    id="click_cover"
    @click="clickAddOne($event)"
    @mouseover="zoomBigger()"
    @mouseleave="zoomBack()"
  ></div>
  <transition name="fade" mode="out-in">
    <div class="ball_container" id="ball_container">
      <div id="tap_ball" class="fade-in" :class="ballStatus"></div></div
  ></transition>
</template>

<script>
import "animate.css";

export default {
  name: "BigBallBall",
  data() {
    return {
      percentage: 0,
      bigger: false,
      clicked: false,
      heartbeat: true,
      gameStart: false,
      barMaxNum: 90,
      startDD: 29,
      startMM: 3,
      startYYYY: 2023,
      startHour: 12,
      startMinutes: 0,
      timeStart: true,
    };
  },
  computed: {
    percent() {
      return this.percentage.toFixed();
    },
    ballStatus() {
      if (this.bigger === true) {
        return "biggerheart";
      } else if (this.clicked === true) {
        return "clickedheart";
      } else {
        return "heartbeat";
      }
    },
  },
  // created() {
  //   var intval = setInterval(() => {
  //     if (this.percentage < 99) this.percentage += 0.6;
  //     else clearInterval(intval);
  //   }, 100);
  // },
  methods: {
    clickAddOne(event) {
      if (this.percentage <= this.barMaxNum) {
        this.percentage += 0.2;
      }
      const addOne = document.createElement("div");
      addOne.innerHTML = `<p style="padding-bottom: 10px;">+</p><p>1</p>`;

      addOne.style = `opacity: 0.848532;display: flex;color: rgba(255, 255, 255, 1);font-size: 50px;font-weight: 700;letter-spacing: 6px;text-transform: uppercase;align-items: center;-webkit-user-select: none;-ms-user-select: none;user-select: none;z-index:1;`;
      addOne.style.position = "absolute";
      addOne.style.left = event.clientX + "px";
      addOne.style.top = event.clientY + "px";
      addOne.classList.add("animate__animated");
      addOne.classList.add("animate__fadeOutUp");
      document.body.appendChild(addOne);

      this.clicked = true;
      this.bigger = false;
      this.heartbeat = false;
      setTimeout(() => {
        if (addOne) {
          this.clicked = false;
          this.bigger = true;
        }
      }, 300);

      setTimeout(() => {
        if (addOne) {
          document.body.removeChild(addOne);
        }
      }, 1000);
    },
    autoAddOne() {
      setInterval(function () {
        const addOne = document.createElement("div");
        addOne.innerHTML = `<p style="padding-bottom: 10px;">+</p><p>1</p>`;

        addOne.style = `opacity: 0.848532;display: flex;color: rgba(255, 255, 255, 1);font-size: 50px;font-weight: 700;letter-spacing: 6px;text-transform: uppercase;align-items: center;-webkit-user-select: none;-ms-user-select: none;user-select: none;z-index:1;`;
        addOne.style.position = "absolute";
        addOne.style.left = Math.floor(Math.random() * (60 - 30) + 30) + "%"; //max:60%, min:30%
        addOne.style.top = Math.floor(Math.random() * (60 - 30) + 30) + "%";
        addOne.style.transform = "translate(-50%,-50%)";
        addOne.classList.add("animate__animated"); //plugin
        addOne.classList.add("animate__fadeOutUp");
        document.body.appendChild(addOne);

        setTimeout(() => {
          if (addOne) {
            document.body.removeChild(addOne);
          }
        }, 1000);
      }, 600);
    },
    zoomBigger() {
      const clickCover = document.getElementById("click_cover");
      clickCover.addEventListener("click", function () {
        return;
      });
      this.bigger = true;
      this.clicked = false;
      this.heartbeat = false;
    },
    zoomBack() {
      this.bigger = false;
      this.clicked = false;
      this.heartbeat = true;
    },
    clickStart() {
      this.gameStart = true;
      var intval = setInterval(() => {
        if (this.percentage < this.barMaxNum) this.percentage += 0.6;
        else clearInterval(intval);
      }, 100);
      this.autoAddOne();
      this.zoomBigger();
    },
  },
  // mounted() {
  //   setInterval(() => {
  //     const now = new Date();
  //     // const hongKongTimezoneOffset = 8 * 60 * 60 * 1000; // 8 hours in milliseconds
  //     const hongKongTime = new Date(now.getTime());
  //     const currentDate = hongKongTime.toLocaleDateString("zh-HK", {
  //       timeZone: "Asia/Hong_Kong",
  //     });
  //     const currentMM = currentDate.split("/")[1];
  //     const currentDD = currentDate.split("/")[0];
  //     const currentYYYY = currentDate.split("/")[2];

  //     const hours = now.getHours();
  //     const minutes = now.getMinutes();
  //     if (
  //       currentDD >= this.startDD &&
  //       currentMM >= this.startMM &&
  //       currentYYYY >= this.startYYYY &&
  //       hours >= this.startHour &&
  //       minutes >= this.startMinutes
  //     ) {
  //       this.timeStart = true;
  //     }
  //   }, 1000);
  // },
  // mounted: function () {
  //   this.autoAddOne();
  //   this.zoomBigger();
  // },
};
</script>
<style scoped>
.start_cover {
  width: 100vw;
  height: 100vh;
  background: rgba(22, 21, 21, 0.89);
  position: absolute;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fcf2d5;
  gap: 20px;
  font-size: 24px;
  font-weight: 800;
}
.start_button {
  background: #f48c1c;
  border: none;
  outline: none;
  border-radius: 10px;
  padding: 15px 30px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.start_logo {
  width: 20px;
}
.ball_container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  background-image: url(../assets/bkgd_P1-01.png);
  background-size: 100%;
  background-position-y: 50%;
  background-repeat: no-repeat;
}
#click_cover {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0px auto;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 10;
  cursor: pointer;
}
#tap_ball {
  width: 45%;
  height: 70%;
  transition: 0.3s;
  background-image: url(../assets/night_sky_EB01_20230201_O-01-01-min.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}
@media only screen and (max-width: 1000px) {
  #tap_ball {
    width: 70%;
  }
}
@media only screen and (max-width: 580px) {
  #tap_ball {
    width: 100%;
  }
}
.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.heartbeat {
  animation: beat 0.3s infinite alternate;
  transform-origin: center;
}
.biggerheart {
  animation: biggerbeat 0.3s infinite alternate;
  transform-origin: center;
}
.clickedheart {
  animation: clickedbeat 0.3s 1 alternate;
  transform-origin: center;
}
.addone_text {
  opacity: 0.848532;
  color: rgba(255, 255, 255, 1);
  font-family: "adiNeue-Bold";
  font-size: 60px;
  font-weight: 700;
  letter-spacing: 6px;
}
.container {
  width: 92%;
  height: 20%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 1%;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}
.loading-bar {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 15px;
  background-image: url(../assets/progress_bg_01.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 3vw;
  padding-right: 3vw;
  max-width: 100%;
  max-height: 100%;
}
.percentage {
  display: block;
  height: 4.5vw;
  transition: 0.3s;
  animation: animate-stripes 0.6s ease-out infinite;
  background-image: url(../assets/chart_02.png);
  background-size: 0.25vw;
  border-radius: 15px;
}
.percentage_number {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  font-weight: 600;
  font-size: 18px;
}
@keyframes animate-stripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}
@keyframes beat {
  to {
    transform: scale(1.1);
    background-image: url(../assets/night_sky_EB01_20230215-01-min.png);
  }
}
@keyframes biggerbeat {
  to {
    transform: scale(1.2);
    background-image: url(../assets/night_sky_EB01_20230215-01-min.png);
  }
}
@keyframes clickedbeat {
  from {
    transform: scale(1.2);
    background-image: url(../assets/night_sky_EB01_20230215-01-min.png);
  }
  to {
    transform: scale(1.1);
    background-image: url(../assets/night_sky_EB01_20230201_O-01-01-min.png);
  }
}
</style>
