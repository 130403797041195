<template>
  <!-- <img class="logo" src="./assets/JEC_logo-02-02.svg" /> -->
  <BigBallBall />
</template>

<script>
import BigBallBall from "./components/BigBallBall.vue";

export default {
  name: "App",
  components: {
    BigBallBall,
  },
};
</script>

<style>
p {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  width: 100vw;
  height: 100vh;
  background-image: url(./assets/Power_save_c_20230130-01-01-01.png);
  background-repeat: no-repeat;
  background-size: 100vw 100vh;
  position: relative;
}
#company_logo {
  /* width: 156px; */
  height: 160px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
/* .logo {
  width: 150px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 5%;
  transform: translate(-50%, 0);
  max-width: 100%;
  max-height: 100%;
} */
</style>
